import { render, staticRenderFns } from "./SundryCreditorDetail.vue?vue&type=template&id=71dd7b92&scoped=true"
import script from "./SundryCreditorDetail.vue?vue&type=script&lang=js"
export * from "./SundryCreditorDetail.vue?vue&type=script&lang=js"
import style0 from "./SundryCreditorDetail.vue?vue&type=style&index=0&id=71dd7b92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71dd7b92",
  null
  
)

export default component.exports