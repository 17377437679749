<template>
  <div v-if="entity">
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="entity && !entity.deleted && !entity.isNew && entity.payments.length == 0 && !readOnlyView"
      :show-save="entity && !entity.deleted && !entity.gstPaidDate && !readOnlyView"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <app-side-menu v-if="entity"
          :min-width="18"
          :fixed-menu="false">
          <aside class="menu"
            slot="side-menu">
            <!-- Consider applying this (max-width) to all side menu titles -->
            <p class="menu-label"
              style="max-width: 200px;">
              <span class="title is-6">
                <strong>
                  {{ entity.invoiceRef }}
                </strong>
              </span>
            </p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: routeTypes.SundryCreditorDetail.name, query: $route.query }"
                  :class="{ 'is-active': $route.name === routeTypes.SundryCreditorDetail.name, }">
                  <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.detailGroup.$error }"
                    :data-badge="$v.detailGroup.$error ? '' : null">
                    Detail Info
                  </span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: routeTypes.SundryCreditorItems.name, query: $route.query }"
                  active-class="is-active">
                  <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.itemsGroup.$error }"
                    :data-badge="$v.itemsGroup.$error ? '' : null">
                    Invoice Items
                  </span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: routeTypes.SundryCreditorPayments.name, query: $route.query }"
                  active-class="is-active">
                  <!-- <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.paymentsGroup.$error || !$v.entity.totalAmount.totalPaid }" -->
                  <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.paymentsGroup.$error }"
                    :data-badge="$v.paymentsGroup.$error ? '' : null">
                    Payments
                  </span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: routeTypes.SundryCreditorAttachments.name, query: $route.query }"
                  active-class="is-active">
                  <span>
                    Attachments
                  </span>
                </router-link>
              </li>
            </ul>
          </aside>
        </app-side-menu>
      </div>
      <div class="column">
        <router-view :entity="entity"
          @amountChanged="amountChanged" />
        <!-- <pre>
          {{ $v }}
        </pre> -->
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal v-if="entity"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Sundry Creditor</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ entity.invoiceRef }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import SundryCreditorService from './SundryCreditorService'
import AppDetailHeader from '@/components/AppDetailHeader'
import AppSideMenu from '@/components/AppSideMenu'
import SundryCreditorFormValidation from './SundryCreditorFormValidation'
import SundryCreditorRoutes from './route-types'
import VendorRoutes from '@/views/vendor/route-types'
import ContactRoutes from '@/views/contact/route-types'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import HttpStatus from '@/components/http-status'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import EventBus from '@/components/EventBus'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'SundryCreditorView',
  components: {
    AppDetailHeader,
    AppSideMenu,
    // SundryCreditorService,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [SundryCreditorFormValidation, StoreMixin],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error || this.$v.itemsGroup.$error || this.$v.paymentsGroup.$error || this.$v.contactsGroup.$error
    },
    routeTypes() {
      return SundryCreditorRoutes
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  // beforeDestroy() {
  //   // Can't destroy because going to sundry creditor
  //   // this.clearSnapshots(this.entity.id)
  // },
  async created() {
    this.getEntity()
    this.$v.detailGroup.$touch()
  },
  mounted() {},
  beforeDestroy() {
    if (_isEmpty(this.returnRoute)) {
      this.setReadOnlyView(false)
    }
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSnapshots(this.$route.params.sundryCreditorId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.sundryCreditorId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'Sundry Creditor'
      if (this.validateError) {
        this.$notification.validationError(title)
        return false
      }
      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))

        if (this.entity.isNew) {
          await SundryCreditorService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          await SundryCreditorService.putEntity(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', 'Save', 'No changes. Not saved')
          return false
        }

        // if (this.isSaveContinue) {
        //   this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        // } else if (response && response.status === HttpStatus.NO_CONTENT) {
        //   await this.getEntity(true)
        //   this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        // } else if (response && response.status === HttpStatus.CREATED) {
        //   await this.getEntity(true)
        //   this.$notification.openNotificationWithType('success', title, `New  ${title}  added`)
        // }
        this.$notification.success(title, isDelete ? `${title} deleted` : 'Save successful')
        if (!this.isSaveContinue) {
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError(title, e)
        }
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.entity.deleted = true
        // save snapshots immediately not waiting for debounce
        this.save(true)
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: this.routeTypes.SundryCreditorListView.name
          })
        }
      }
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    print() {
      const params = Object.assign({
        ReportTitle: 'Sundry Creditor Detail',
        CompanyID: this.$userInfo.companyId,
        CreditorInvoiceID: this.entity.id,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.id
      emailer.subject = 'Sundry Creditor Detail'
      emailer.reportName = 'AT_SundryCreditorDetails'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_SundryCreditorDetails' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.getEntity(true)
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    amountChanged(values) {
      this.entity.amount = values.exGst
      this.entity.totalAmount = values.incGst
      this.entity.gstAmount = values.gst
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))

    const isEditVendor = to.name === VendorRoutes.VendorNew.name || to.name === VendorRoutes.VendorDetail.name
    const isEditContact = to.name === ContactRoutes.ContactDetail.name
    if (isEditVendor || isEditContact) {
      next()
    } else if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      console.log(this.snapshotDiff)
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.id)
      if (to.name !== PrintPreviewRoutes.PrintPreview.name) {
        this.setReturnRoute({})
      }
      next()
    }
  }
}
</script>
