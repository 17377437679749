import FqApi from '@/services/fq-api'

export default {
  upload(formData, objectId, quoteId='xxx', quoteAttachmentCategoryId = 'xxx') {
    return FqApi.post(`/attachments?objectId=${objectId}&quoteId=${quoteId}&quoteAttachmentCategoryId=${quoteAttachmentCategoryId}`, formData)
  },

  delete(id) {
    return FqApi.delete(`/attachments?ObjectId=${id}`)
  },

  get(id) {
    return FqApi.get(`/attachments?id=${id}`, {
      responseType: 'arraybuffer'
    })
  }
}
