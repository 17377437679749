<template>
  <div class="field has-addons">
    <p v-if="readOnly"
      class="control is-expanded">
      <input readonly="readonly"
        class="input is-static"
        type="text"
        v-model="contactText">
    </p>
    <p v-else
      class="control is-expanded">
      <multiselect v-if="data"
        :value="value"
        @input="change"
        track-by="key"
        label="value"
        placeholder="Select a contact"
        :options="data"
        :loading="isLoading"
        :internal-search="false"
        :options-limit="assetFilter.pageSize"
        @search-change="search"
        :searchable="true"
        :show-labels="false"
        :allow-empty="true">
        <template slot="option"
          slot-scope="props">
          <span>{{ props.option.value }}</span>
        </template>
        <span class="has-text-danger"
          slot="noResult">Contacts not found.</span>
      </multiselect>
    </p>
    <p v-if="!readOnly"
      class="control">
      <a class="button is-primary tooltip"
        data-tooltip="Add contact"
        @click="add"
        :disabled="!editable"
        v-if="contactId === ''">
        <span class="icon is-small">
          <i class="mdi mdi-plus mdi-24px" />
        </span>
      </a>
      <a class="button is-success tooltip"
        data-tooltip="Edit contact"
        @click="edit"
        v-if="contactId !== ''">
        <span class="icon is-small">
          <i class="mdi mdi-pencil mdi-24px" />
        </span>
      </a>
    </p>
  </div>
</template>
<script>
import _debounce from 'lodash.debounce'
import ContactService from '@/views/contact/ContactService'
import StoreMixin from './storeMixin'
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'
import { ContactPayload } from '@/classes'

export default {
  name: 'SundryCreditorVendorContact',
  components: {
    Multiselect
  },
  mixins: [StoreMixin],
  props: {
    value: null,
    readOnly: {
      required: true,
      type: Boolean
    },
    vendor: {
      type: Object
    }
  },
  data() {
    return {
      data: [],
      isLoading: false,
      isExists: false,
      searchValue: '',
      assetFilter: {
        objectId: Guid.empty(),
        searchKey: ''
        // pageIndex: 1,
        // pageSize: 50
      }
    }
  },
  computed: {
    contactId: function() {
      if (this.value && this.value.key && this.$guid.validGuid(this.value.key)) {
        return this.value.key
      }

      return ''
    },
    contactText: function() {
      if (this.value && this.value.value) {
        return this.value.value
      } else {
        return ''
      }
    },
    editable: function() {
      return !(!this.vendor || this.readonly)
    }
  },
  watch: {
    vendor: function(value, oldValue) {
      if (!value) {
        this.reset()
      } else {
        if (value && oldValue && value.key === oldValue.key) {
          // empty
        } else {
          this.getData()
        }
      }
    }
  },
  async mounted() {
    if (this.vendor) {
      this.assetFilter.objectId = this.vendor.key
      await this.getDataOnly()
    }
  },
  methods: {
    // setDefault() {
    //   if (this.contactId !== '') {
    //     this.isExists = true
    //     this.data.push(this.value)
    //   } else {
    //     this.isExists = false
    //     this.getData()
    //   }
    // },
    reset: function() {
      // const contact = {
      //   key: this.value.key,
      //   value: this.value.value
      // }
      // this.$emit('input', contact)
    },
    getDataOnly: async function() {
      const res = await ContactService.getContactByObjectId(this.assetFilter)
      this.data = res.data.list
    },
    getData: async function() {
      this.reset()

      if (!this.vendor) {
        this.data = []
      } else {
        this.isLoading = true
        if (this.searchValue && this.value) {
          this.$emit('input', null)
        }

        this.assetFilter.objectId = this.vendor.key
        this.assetFilter.searchKey = this.searchValue
        const res = await ContactService.getContactByObjectId(this.assetFilter)
        this.data = res.data.list
        this.isLoading = false
      }
    },
    search: _debounce(function(val) {
      this.searchValue = val

      if (val === '') {
        return
      }

      if (this.value && this.value.value) {
        if (val === this.value.value) {
          return
        }
      }

      this.$emit('input', null)
      this.isExists = false

      this.getData()
    }, 500),
    change(val) {
      if (val) {
        this.isExists = true
      } else {
        this.isExists = false
      }
      this.$emit('input', val)
    },
    async add() {
      if (!this.editable) {
        return
      }
      console.log('add contact')
      this.setContactReturnRoute(this.$route)
      const payload = new ContactPayload(this.searchValue, this.vendor.key, 3, this.vendor.value)
      this.addStoreContact(payload)
    },
    async edit() {
      if (!this.editable) {
        return
      }
      console.log('edit contact')
      this.setContactReturnRoute(this.$route)
      this.editStoreContact(this.value.key)
    }
  }
}
</script>
