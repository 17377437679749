<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Detail Info
                <span v-show="!entity.isNew"
                  class="icon has-text-info tooltip is-tooltip-info"
                  :class="[ canChangeReadOnly ? 'readonly-pointer' : '' ]"
                  :data-tooltip="readOnlyView ? 'Invoice is locked' : 'Invoice is unlocked'"
                  @click="toogleReadOnly()">
                  <i class="icon mdi"
                    :class="[ readOnlyView ? 'mdi-lock-outline' : 'mdi-lock-open-outline' ]" />
                </span>
              </p>
              <p v-if="isGstPaid"
                class="subtitle has-text-warning">Invoice cannot be updated</p>
            </div>
            <div class="column is-narrow">
              <span v-if="isGstPaid"
                class="tag is-warning is-pulled-right">
                GST paid on {{ $filters.formatDateTimezone(entity.gstPaidDate, $userInfo.locale) }}</span>
              <span class="is-pulled-right">&nbsp;</span>
              <span v-if="entity.isFullyPaid"
                class="tag is-warning is-pulled-right">
                Fully Paid
              </span>
            </div>
          </div>
          <div class="columns">
            <div class="column field">
              <label class="label">Invoice No.</label>
              <div class="control"
                v-if="!entity.isNew && !readOnly">
                <input class="input is-static"
                  v-model="entity.invoiceRef"
                  type="text"
                  placeholder="Invoice No."
                  readonly>
              </div>
              <div class="control"
                v-else>
                <input ref="name"
                  class="input is-capitalized"
                  :class="{ 'is-static' : !isNew || readOnly }"
                  :readonly="readOnly"
                  type="text"
                  v-model="entity.invoiceRef"
                  v-fq-validate="$v.entity.invoiceRef">
                <span class="help is-danger"
                  v-if="!$v.entity.invoiceRef.required && !readOnly">
                  Invoice number is required
                </span>
                <span class="help is-danger"
                  v-if="!$v.entity.invoiceRef.isValid && !readOnly">
                  Invoice number already in use
                </span>
              </div>
            </div>
            <div class="column is-narrow">
              <div v-if="readOnly"
                class="field">
                <label class="label">Invoice Date</label>
                <div class="control">
                  <input class="input is-static"
                    :value="$filters.formatDateTimezone(entity.invoiceDate, $userInfo.locale)"
                    readonly>
                </div>
              </div>
              <div v-else-if="$userInfo && !readOnly"
                class="field is-narrow">
                <label class="label">Invoice Date</label>
                <v-date-picker v-model="selectedInvoiceDate"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  :min-date="firstDayOfMonth"
                  @input="selectedInvoiceDate = $filters.fromISOWithCurrentTime($event.toISOString())"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Date To..."
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>

          <div class="is-divider" />

          <div class="field">
            <label class="label">Vendor</label>
            <div class="control">
              <sundry-creditor-vendor v-model="entity.vendor"
                :is-new="entity.isNew"
                :read-only="readOnly"
                @vendorChanged="vendorChanged" />
            </div>
          </div>

          <div class="field">
            <label class="label">Contact</label>
            <div class="control">
              <sundry-creditor-vendor-contact v-model="entity.contact"
                :read-only="readOnly"
                :vendor="entity.vendor" />
            </div>
          </div>

          <div class="field-body">
            <div class="field is-narrow">
              <label class="label">ABN</label>
              <div class="control">
                <the-mask v-if="!readOnly && $company.info.countryCode === 'AU'"
                  id="abn"
                  :class="{ 'is-static' : readOnly, 'is-danger': $v.entity.abn.$error }"
                  :readonly="readOnly"
                  name="abn"
                  ref="abn"
                  class="input abn"
                  placeholder="## ### ### ###"
                  v-model="entity.abn"
                  :mask="['## ### ### ###']" />
                <div v-else>
                  <input class="input is-uppercase"
                    id="abn"
                    ref="abn"
                    type="text"
                    v-model="entity.abn"
                    maxlength="12"
                    :disabled="readOnly">
                </div>
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.abn.minLength">ABN must be at least {{ $v.entity.abn.$params.minLength.min }} letters.</span>
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.abn.validAbn && $v.entity.abn.minLength">ABN invalid</span>
                <span class="help is-danger"
                  v-if="entity.vendor && !entity.abn && $company.info.isWithHold">
                  This vendor does not have an ABN. You will be required to with hold 49.00% of the invoice.
                </span>
              </div>
              <!-- <span class="help">{{ entity.abn }}</span> -->
            </div>
            <div class="field">
              <label class="label">G/L Account</label>
              <input v-if="readOnly"
                class="input is-static"
                readonly="readonly"
                :value="entity.glCode.displayName">
              <div v-else
                class="control is-expanded">
                <multiselect v-if="glAccountListCombo && !readOnly"
                  v-model="entity.glCode"
                  @select="glAccountSelected($event)"
                  :options="glAccountListCombo"
                  placeholder="Select account"
                  label="displayName"
                  track-by="accountNo"
                  :allow-empty="false" />
                <input class="input is-static"
                  :value="entity.glCode.displayName"
                  v-if="readOnly"
                  readonly="readonly">
                <span class="help is-danger"
                  v-if="!$v.entity.glCode.required">
                  G/L Account is required
                </span>
              </div>
            </div>

            <div class="field is-narrow">
              <label class="label">Exported</label>
              <div class="pretty p-icon p-curve p-smooth p-bigger">
                <input id="chk-invoice-exported"
                  name="chk-invoice-exported"
                  v-model="entity.exported"
                  type="checkbox"
                  :disabled="readOnlyView || !$userInfo.isSupportUser">
                <div class="state p-primary">
                  <i class="icon mdi mdi-check" />
                  <label>Exported</label>
                </div>
              </div>
            </div>

          </div>

          <div class="is-divider" />

          <div class="columns">
            <div class="column field">
              <label class="label">Quote No.</label>
              <div class="control">
                <input ref="name"
                  class="input quoteNo"
                  :class="{ 'is-static' : readOnly }"
                  :readonly="readOnly"
                  type="text"
                  v-model="entity.quoteNo"
                  v-fq-validate="$v.entity.quoteNo">
                <!-- <span class="help is-warning"
              v-if="!$v.entity.quoteNo.$pending">
                      Checking...
                    </span> -->
                <span class="help is-danger"
                  v-if="!$v.entity.quoteNo.isValid">Quote no. does not exist</span>
              </div>
            </div>
            <div class="column field is-narrow">
              <label class="label">Invoice Date</label>
              <div v-if="readOnly"
                class="control">
                <input class="input is-static"
                  :value="$filters.formatDateTimezone(entity.due, $userInfo.locale)"
                  readonly>
              </div>
              <v-date-picker v-else
                v-model="selectedDueDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="selectedDueDate = $filters.fromISOWithCurrentTime($event.toISOString())"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Date To..."
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>

          </div>

          <div class="is-divider" />
          <div class="columns">
            <div class="column field">
              <label class="label">Capital</label>
              <div v-if="!readOnly"
                class="control">
                <!-- Note 14 -->
                <!-- :disabled="true" -->
                <input id="capital"
                  name="capital"
                  class="switch is-rounded"
                  type="checkbox"
                  v-model="entity.isCapital">
                <label for="capital" />
              </div>
              <div v-else
                class="control">
                <input id="capital"
                  name="capital"
                  class="switch is-rounded"
                  type="checkbox"
                  :disabled="true"
                  v-model="entity.isCapital">
                <label for="capital" />
              </div>
            </div>
            <div class="column field">
              <label class="label">Include in GST report</label>
              <div class="control">
                <input id="includeInGst"
                  :disabled="true"
                  name="includeGst"
                  class="switch is-rounded"
                  type="checkbox"
                  v-model="entity.isIncludeGst">
                <label for="includeInGst" />
              </div>
            </div>
          </div>
        </article>
      </div>
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Summary</p>
            </div>
            <div class="column">
              <div v-if="!readOnly"
                class="control is-pulled-right">
                <!-- :class="{'is-success' : $v.entity.totalAmount.minValue , 'is-danger' : !$v.entity.totalAmount.minValue}" -->
                <a class="button tooltip is-success"
                  :data-tooltip="addMoreGlDisabled? 'Please enter Invoice No.': 'Add Invoice Items'"
                  :disabled="addMoreGlDisabled"
                  @click="addMoreGlItem()">
                  <span class="icon">
                    <i class="mdi mdi-18px mdi-plus" />
                  </span>
                  <span>Invoice Items</span>
                </a>
              </div>
            </div>
          </div>
          <label class="label">Items</label>
          <!-- <label class="help is-danger"
            v-if="!$v.entity.totalAmount.minValue">Items amount must be more than 0</label> -->

          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead>
              <tr>
                <th>Description</th>
                <th class="has-text-right">
                  Total
                </th>
              </tr>
            </thead>
            <tbody v-if="entity.items.length > 0">
              <tr v-for="(item) in entity.items"
                :key="item.id"
                v-show="!item.isDeleted">
                <td>
                  {{ item.itemDesc }}
                </td>
                <td class="has-text-right">
                  {{ item.amountIncGst | formatNumber($userInfo.locale) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="has-text-centered"
                  colspan="2">
                  <span icon="icon is-medium">
                    <i class="mdi mdi-36px mdi-dots-horizontal mdi-dark mdi-inactive" />
                  </span>
                </td>
              </tr>
            </tbody>
            <tfoot class="has-multiple-totals">
              <tr>
                <th class="has-text-right">Total excl. GST</th>
                <th class="has-text-right has-text-success">{{ entity.amount | formatCurrency($userInfo.locale) }}</th>
              </tr>
              <tr>
                <th class="has-text-right">GST</th>
                <th class="has-text-right has-text-success">{{ entity.gstAmount | formatCurrency($userInfo.locale) }}</th>
              </tr>
              <tr>
                <th class="has-text-right">Total incl. GST</th>
                <th class="has-text-right has-text-success">{{ entity.totalAmount | formatCurrency($userInfo.locale) }}</th>
              </tr>
            </tfoot>
          </table>

          <label class="label">Payments</label>
          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead>
              <tr>
                <th>Date</th>
                <th>Cheque No.</th>
                <th class="has-text-right">
                  Paid
                </th>
              </tr>
            </thead>
            <tbody v-if="entity.payments.length > 0">
              <tr v-for="(item) in entity.payments"
                :key="item.id"
                v-show="!item.isDeleted">
                <td>
                  {{ $filters.formatDateTimezone(item.payDate, $userInfo.locale) }}
                </td>
                <td>
                  {{ item.cheque }}
                </td>
                <td class="has-text-right">
                  {{ item.paid | formatNumber($userInfo.locale) }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="has-text-centered"
                  colspan="3">
                  <span icon="icon is-medium">
                    <i class="mdi mdi-36px mdi-dots-horizontal mdi-dark mdi-inactive" />
                  </span>
                </td>
              </tr>
            </tbody>
            <tfoot class="has-multiple-totals">
              <tr>
                <th class="has-text-right"
                  colspan="2">Total Paid</th>
                <th class="has-text-right has-text-success">{{ totalPaid | formatCurrency($userInfo.locale) }}</th>
              </tr>
              <tr>
                <th class="has-text-right"
                  colspan="2">Balance</th>
                <th class="has-text-right has-text-success">{{ entity.totalAmount - totalPaid | formatCurrency($userInfo.locale) }}</th>
              </tr>
            </tfoot>
          </table>

        </article>
      </div>
    </div>
  </div>
</template>
<script>
import VendorService from '@/views/vendor/VendorService'
import SundryCreditorVendor from './SundryCreditorVendor'
import SundryCreditorVendorContact from './SundryCreditorVendorContact'
import { InputValidationDirective } from '@/components/directives'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import Multiselect from 'vue-multiselect'
import { TheMask } from 'vue-the-mask'
import StoreMixin from './storeMixin'
import SundryCreditorRoutes from './route-types'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import Guid from '@/components/Guid'

export default {
  inject: ['$vv'],
  components: {
    SundryCreditorVendor,
    SundryCreditorVendorContact,
    Multiselect,
    TheMask
  },
  mixins: [InputValidationDirective, DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  props: {
    entity: null,
    isTiled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      glAccountListCombo: null,
      selectedInvoiceDate: null,
      selectedDueDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv ? this.$vv : this.$v
    },
    isNew() {
      return this.entity.isNew
    },
    gstRate() {
      return this.entity.gstRate / 100
    },
    isGstReportDisabled() {
      const rs = this.entity.gstAmount > 0
      return rs
    },
    invoiceDate() {
      if (this.entity.invoiceDate) {
        return new Date(this.entity.invoiceDate)
      }

      return null
    },
    routeTypes() {
      return SundryCreditorRoutes
    },
    addMoreGlDisabled() {
      return !this.entity.invoiceRef
    },
    readOnly() {
      return (!this.entity.gstPaidDate && !this.readOnlyView) === false
    },
    isGstPaid() {
      return !this.entity.gstPaidDate === false
    },
    totalPaid() {
      let rs = 0
      this.entity.payments.forEach((d) => {
        rs += d.paid
      })
      return rs
    },
    firstDayOfMonth() {
      if (this.$company.setting.allowInvoicePreviousMonth) {
        return null
      }
      let invoiceDate = new Date()
      if (!this.entity.isNew) {
        invoiceDate = new Date(`${this.entity.invoiceDate}`)
        return this.$filters.firstDayOfMonth(invoiceDate.getFullYear(), invoiceDate.getMonth())
      } else {
        return this.$filters.firstDayOfMonth()
      }
    }
  },
  watch: {
    'entity.gstAmount': function (newValue) {
      if (!newValue) {
        this.entity.isIncludeGst = false
      } else {
        this.entity.isIncludeGst = true
      }
    },
    selectedInvoiceDate: function (newVal, oldVal) {
      if (newVal) {
        this.entity.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.entity.invoiceDate = null
      }
    },
    selectedDueDate: function (newVal, oldVal) {
      if (newVal) {
        this.entity.due = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.entity.due = null
      }
    }
  },
  created() {
    if (this.entity.invoiceDate) {
      this.selectedInvoiceDate = new Date(`${this.entity.invoiceDate}`)
    }
    if (this.entity.due) {
      this.selectedDueDate = new Date(`${this.entity.due}`)
    }
  },
  mounted() {
    this.getData()
    // this.$v.detailGroup.$touch()

    // setTimeout(() => {
    //   this.$refs.name.focus()
    // }, 500)
  },
  methods: {
    toogleReadOnly() {
      if (this.canChangeReadOnly) {
        this.setReadOnlyView(!this.readOnlyView)
      }
    },
    canChangeReadOnly() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    },
    glAccountSelected(option) {
      if (option) {
        const isAsset = option.accountType === 'ASS'
        this.entity.isCapital = isAsset || (!isAsset && this.entity.totalAmount >= 1000)
      }
    },
    addMoreGlItem() {
      if (this.addMoreGlDisabled) {
        return
      }

      this.entity.items.push({
        itemDesc: '',
        amountExGst: '',
        amountIncGst: 0,
        gst: 0,
        itemGlCode: this.entity.glCode,
        isCapitalItem: this.entity.isCapital,
        isGstReport: this.entity.isIncludeGst
      })

      this.$v.$touch()

      this.$router.push({
        name: this.routeTypes.SundryCreditorItems.name,
        query: this.$route.query
      })
    },
    onDrop(e) {
      e.preventDefault()
    },
    getData() {
      VendorService.getGlAccountListComboProto().then((result) => {
        this.glAccountListCombo = result
      })
    },
    totalAmountChanged() {
      const exGst = roundAwayFromZero(this.entity.totalAmount / (1 + this.gstRate), 2)
      this.entity.amount = exGst
      // Is this float/double?
      // I was getting .9999 in GST amount
      this.entity.gstAmount = roundAwayFromZero(this.entity.totalAmount - exGst, 2)

      // Brewing
      // Follow OLD UI as requested
      if (this.entity.items.length === 1) {
        this.entity.items[0].amountIncGst = this.entity.totalAmount
        this.entity.items[0].amountExGst = this.entity.amount
        this.entity.items[0].gst = this.entity.gstAmount
      }
    },
    gstAmountChanged() {
      // Do Nothing
      // Follow OLD UI as requested
    },
    async vendorChanged(ev) {
      if (ev) {
        var abn = await VendorService.getAbn(ev.key)

        this.entity.abn = new Date().getTime()
        const vm = this
        setTimeout(function () {
          vm.entity.abn = abn
        })

        var glAccount = await VendorService.getGlAccount(ev.key)

        this.entity.glCode = glAccount

        // Set isCapital = true if vendor's default glAccount is an asset,
        // or non asset but more than 1000 in value as per items logic
        if (this.entity.glCode) {
          const isAsset = glAccount.accountType === 'ASS'
          this.entity.isCapital = isAsset || (!isAsset && this.entity.totalAmount >= 1000)
        }
        this.entity.contact.key = Guid.empty()
        this.entity.contact.value = ''
      } else {
        this.entity.contact.key = Guid.empty()
        this.entity.contact.value = ''
        this.entity.abn = null
        this.entity.glCode = {}
      }
    }
  },
  beforeRouteLeave: async function (to, from, next) {
    next()
    // const message = 'Validation errors. Please fix before proceeding'
    // const title = 'Sundry Creditor Details'
    // // this.invalids = this.getVuelidationErrors()
    // if (this.$v.detailGroup.$error && !this.entity.isNew) {
    //   this.$router.replace(from.path)
    //   this.$notification.openNotificationWithType('danger', title, message)
    // } else {
    //   next()
    // }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.abn {
  width: 15ch;
}
.quoteNo {
  width: 15ch;
}
</style>
