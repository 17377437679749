<template>
  <div class="field has-addons">
    <p v-if="readOnly"
      class="control is-expanded">
      <input readonly="readonly"
        class="input is-static"
        type="text"
        v-model="vendorText">
    </p>
    <p v-else
      class="control is-expanded">
      <multiselect v-if="vendors"
        :class="{ 'is-danger' : $v.entity.vendor.$error }"
        :value="value"
        @input="changeVendor"
        deselect-label="Can't remove this value"
        track-by="key"
        label="value"
        placeholder="Select a vendor"
        :options="vendors"
        :loading="isVendorLoading"
        :internal-search="false"
        :options-limit="assetFilter.pageSize"
        @search-change="searchVendor"
        :searchable="true"
        :show-labels="false"
        :allow-empty="true">
        <template slot="option"
          slot-scope="props">
          <span>{{ props.option.value }}</span>
        </template>
        <span class="has-text-danger"
          slot="noResult">Vendor not found.</span>
      </multiselect>
      <span v-if="!$v.entity.vendor.required"
        class="help is-danger">Vendor is required</span>
    </p>
    <p class="control"
      v-if="isNew">
      <a class="button is-primary tooltip"
        data-tooltip="Add vendor"
        @click="addVendor"
        v-if="vendorId === ''">
        <span class="icon is-small">
          <i class="mdi mdi-plus mdi-24px" />
        </span>
      </a>
      <a class="button is-success tooltip"
        data-tooltip="Edit vendor"
        @click="editVendor"
        v-if="vendorId !== ''">
        <span class="icon is-small">
          <i class="mdi mdi-pencil mdi-24px" />
        </span>
      </a>
    </p>
  </div>
</template>
<script>
import _debounce from 'lodash.debounce'
import VendorService from '@/views/vendor/VendorService'
import { InputValidationDirective } from '@/components/directives'
import StoreMixin from './storeMixin'
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'

export default {
  name: 'SundryCreditorVendor',
  components: {
    Multiselect
  },
  mixins: [InputValidationDirective, StoreMixin],
  inject: ['$vv'],
  props: {
    value: null,
    isNew: {
      required: true,
      type: Boolean
    },
    readOnly: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      vendors: [],
      isVendorLoading: false,
      vendorExists: false,
      searchValue: '',
      assetFilter: {
        assetId: Guid.empty(),
        assetName: '',
        pageIndex: 1,
        pageSize: 50
      }
    }
  },
  computed: {
    $v() {
      return this.$vv
    },
    vendorId: function() {
      if (this.value && this.value.key) {
        return this.value.key
      }

      return ''
    },
    vendorText: function() {
      if (this.value && this.value.value) {
        return this.value.value
      } else {
        return ''
      }
    }
  },
  mounted() {
    if (this.vendorId !== '') {
      this.vendorExists = true

      this.vendors.push(this.value)
    } else {
      this.vendorExists = false
      this.getVendorsDropdown()
    }
  },
  methods: {
    getVendorsDropdown: async function() {
      this.isVendorLoading = true
      if (this.searchValue && this.value) {
        this.$emit('input', null)
      }

      this.assetFilter.name = this.searchValue
      this.vendors = await VendorService.getVendorDropdownProto(this.assetFilter)

      this.isVendorLoading = false
    },
    searchVendor: _debounce(function(val) {
      this.searchValue = val

      if (val === '') {
        return
      }

      if (this.value && this.value.value) {
        if (val === this.value.value) {
          return
        }
      }

      this.$emit('input', null)
      this.vendorExists = false

      this.getVendorsDropdown()
    }, 500),
    changeVendor(val) {
      if (val) {
        this.vendorExists = true
      } else {
        this.vendorExists = false
      }
      this.$emit('input', val)
      this.$emit('vendorChanged', val)
    },
    async addVendor() {
      this.setVendorReturnRoute(this.$route)
      this.addStoreVendor(this.searchValue)
    },
    async editVendor() {
      this.setVendorReturnRoute(this.$route)
      this.editStoreVendor(this.value.key)
    }
  }
}
</script>
